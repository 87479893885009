/* Styles for the input fields with green outline on click */


.css-19kzrtu {
  padding:0px !important;
}

.search-containerr {
  display: flex;
  align-items: center;
  border: 1px solid rgb(137, 143, 148);
  padding: 18px 15px;
  border-radius: 10px;
}
.css-ekeie0 {
  height: 100%;
  outline: 0px;
  display: block !important;
  /* -webkit-box-pack: center; */
  /* justify-content: center; */
  /* -webkit-box-align: center; */
  /* align-items: center; */
}
.css-uhb5lp {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  margin:0px !important;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 0px) !important;
  max-width: 600px;
}

.search-iconn {
  margin-right: 8px;
  display: flex;
  align-items: center;
  color: #888;
}

.search-inputt {
  border: none;
  outline: none;
  flex: 1;
  font-size: 16px;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding-right: 65px;
  height: 62px;
  border-radius: 10px;
}
.MuiAutocomplete-hasPopupIcon.css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding-right: 39px;
  height: 62px !important;
  border-radius: 10px !important;
}

.css-hz1bth-MuiDialog-container{
  position: relative !important;
}


.addDialog .css-hz1bth-MuiDialog-container .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  background-color: #fff;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 602px;
  position: absolute;

  bottom: 50%;
  left: 50%;
  transform: translate(-50% ,50%);

  /* translate: -50% -50%;
   */
}
.css-1jaw3da {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  margin-left: -11px;
  margin-right: 35px;
}
.addDialog .css-ekeie0 {
  background-color: #fff;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 602px;
  position: absolute;

  bottom: 50%;
  left: 50%;
  transform: translate(-50% ,50%);

  /* translate: -50% -50%;
   */
}
.des{
  font-weight: 400;
  font-size: 14px;
  color: #898F94;
  cursor: pointer;
  margin-top: 15px;
}
.subtotal{
  font-weight: 700;
  font-size: 17px;
  color: #898F94;
  cursor: pointer;
  /* margin-top: 15px; */
}.subtotall{
  font-weight: 700;
  font-size: 17px;
  color: #898F94;
  cursor: pointer;
  margin-top: 15px;
}
.title{
  font-weight: 700;
  font-size: 17px;
  color:black;
  margin: 0px;
}
.buttons{
  display: flex;
  justify-content: end;
  align-items: center;
  column-gap: 15px;
  margin:10px 25px 20px 0px;

}
.input{
  border-radius: 10px;
  height: 40px;
  margin-top: 15px;
  width: 110px;
  padding-left: 10px;
}
.quantity{
  border-radius: 10px;
  height: 40px;
  margin-top: 15px;
  width: 50px;
  padding-left: 10px;
}















input:focus {
    outline-color: rgb(8, 105, 8);
  }
  
  /* Styles for the Rich Text Editor container */
  /* You can adjust the styles as per your design requirements */
  .rich-text-editor-container {
    margin: 10px 0px;
    border: 1px solid black; 
    padding: 10px;
    border-radius: 4px;
    height: 150px !important;
    font-size:14px;
    font-weight:400;
    font-family:"Roboto", "Helvetica", "Arial", sans-serif;

  }

  .ql-container.ql-snow {
    border: 1px solid #ccc;
    height: 86px !important;
}

  .ql-container.ql-snow {
    border: 1px solid #ccc;
    height: 259px;
}


.fileUpload {
    cursor: pointer;
    background-color: #F0F5FD;
    color: blue;
    padding: 10px 20px;
    border-radius: 5px;
    font-size:14px;
    font-weight:400;
    align-items: center;
    text-align: center;
    border: none;
}
.linkUpload {
    cursor: pointer;
    border: none;
    background: none;
     color: blue;
    padding: 10px 20px;
    font-size:14px;
    font-weight:400;
    align-items: center;
    text-align: center;
}
.linkUpload:hover{
    border-bottom: 1px solid black;
    color: black;
}
.message{
    font-size: 18px;
    font-weight: 400;
    color: #898F94;
    font-size:14px;
    font-weight:400;
    align-items: center;
    text-align: center;
}



.css-1vbc0rj-MuiInputBase-input-MuiFilledInput-input {
  
    border: 0;

    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;

    width: 100%;
  
    padding-top: 16px !important;
    padding-right: 12px;
    padding-bottom: 16px !important;
    padding-left: 3px;
}


.css-1o2n4nu-MuiInputBase-input-MuiFilledInput-input {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding-top: 16px !important;
    padding-right: 12px;
    padding-bottom: 16px !important;
    padding-left: 3px;
}



.css-1w4vsez-MuiFormControl-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    vertical-align: top;
    width: 100%;
    margin: 0px !important;
}



.css-10botns-MuiInputBase-input-MuiFilledInput-input {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding-top: 16px !important;
    padding-right: 12px;
    padding-bottom: 16px !important;
    padding-left: 3px;
}




.QuantitySectionMain{
    display: grid;
    position:relative;
    grid-template-columns: 45% 51%;
    align-items:center;
    column-gap:10px;
    justify-content:start;
}
.inventoryLocation{
    padding-top: 51px;
}
.css-ahj2mt-MuiTypography-root {
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 14px !important;

}

.dlt{
  display: none !important;
}
.selectImagesArray:hover .dlt{
  display: block !important;

}


@media screen and (max-width: 1788px) {
  .QuantitySectionMain{
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .inventoryLocation{
    margin-top: 15px;
  }
  .inputQuantity{
    width: 100%;
  }
  .inventoryLocation{
    padding-top: 0px;
}
  }

  