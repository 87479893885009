.rich-text-editor-container {
    margin: 10px 0px;
    border: 1px solid black;
    padding: 10px;
    border-radius: 4px;
    height: 150px !important;
    font-size: 14px;
    font-weight: 400;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.ql-container.ql-snow {
    border: 1px solid #ccc;
    height: 86px !important;
}

.dltIcon {
    position: absolute;
    top: 0px;
    right: -3px;
    margin: auto;
}



.image-container {
    position: relative;
  }
  
  .dltIcon {
    display: none !important;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .image-container:hover .dltIcon {
    display: block !important;
  }
    .dltIconPreview {
    display: none !important;
 
  }
  
  .image-container-preview:hover .dltIconPreview {
    display: block !important;
  }
  
  .title{
    font-size: 17px;
    font-weight: 700;
    color: black;
    margin: 5px;
    
  }
  .value{
    font-size: 14px;
    font-weight: 400;
    color: black;
  }