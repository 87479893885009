.order .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 15px !important;
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12);
    /* margin: 32px; */
    position: relative;
    overflow-y: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    max-height: calc(100% - 64px);
    max-width: 390px !important;
    overflow-x: hidden !important;
}


.itemOrder{
    padding: 0px;
    font-size:14px;
    font-weight:400;
    padding-left:10px;
    position:relative;
    background-color:white;
  }
  .arrowD{
    display: none;
  }
  
  .itemOrder:hover  .arrowD{
    display: block;
    position:absolute;
    left:24%;
    bottom:-15%
  }