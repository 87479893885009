.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 9px;
    border-radius: 10px;
}

.green-border {
    border: 2px solid green;
  }
  
  .save {
    width: 99px;
    height: 30px;
    border: none;
    font-size: 14px;
    border-radius: 5px;
    font-weight: 400;
    cursor: pointer;
}

.optionQuantity{

    display: grid;
    grid-template-columns: 25% 24% 22% 25%;
    justify-content: start;
    align-items: center;
    column-gap: 10px;
    margin: 20px 10px;
}

.manageInventory {
  background: rgb(77, 154, 25);
  color: white;
  width: 150px;
  height: 30px;
  border-radius: 5px;
  margin-left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 7px;
  border: none;
  margin-top: 20px;
  cursor: pointer;
 
}

@media screen and (max-width: 1788px) {

  .optionQuantity {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    justify-content: start;
    align-items: center;
    column-gap: 10px;
    margin: 20px 10px;
}

.manageInventory {
  background: rgb(77, 154, 25);
  color: white;
  width: 150px;
  height: 30px;
  border-radius: 5px;
  margin-left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 7px;
  cursor: pointer;
  
  margin-top: 20px;
  margin-left: 123%;
  border-bottom: 1px solid black;
}
}

  