/* .css-16ru99o-control{
    height: 58px !important;
    border-radius: 10px !important;
}
.css-qbdosj-Input{
    height: 52px !important;
    border-radius: 10px !important;
}
.css-1dan5zr-control{
    height: 58px !important;
    border-radius: 10px !important;
} */


.css-13cymwt-control{
    height: 57px !important;
    border-radius: 10px !important;
}
.css-t3ipsp-control{
    height: 57px !important;
    border-radius: 10px !important;
}