 .addDialog .css-hz1bth-MuiDialog-container .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    background-color: #fff;
    height: 318px !important;
    overflow-y: auto;
    overflow-x: hidden;
    width: 602px;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50% ,50%);
    /* translate: -50% -50%; */
 } 